import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [4,3];

export const dictionary = {
		"/": [~7],
		"/(app)/(public)/explore": [~9,[2,4]],
		"/(app)/(private)/home": [~8,[2,3]],
		"/(landing)/landing": [~14,[6]],
		"/(auth)/login": [12,[5]],
		"/(app)/(public)/reservation-calendar": [~10,[2,4]],
		"/(app)/(public)/restaurant/[slug]": [~11,[2,4]],
		"/(auth)/signup": [~13,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';